import React, { useCallback, useEffect, useRef, useState } from "react";
import "tailwindcss/tailwind.css";
import io from "socket.io-client";
import "./Navbar.css";
import { FaDiscord } from "react-icons/fa";

import { AiOutlineAudio, AiOutlineLoading } from "react-icons/ai";
import { GiCircle, GiConverseShoe } from "react-icons/gi";
import { MdOutlineArrowOutward } from "react-icons/md";
import bgImage from "../src/bgtwo.png";
import { TfiInfinite } from "react-icons/tfi";
import logo from "./logo.png";
import stuff from "./stuff.gif";
import still from "./still.png";
// import GifPlayerModified from "./GifPlay.js";
import MorphingCircle from "./animation.js";
import infinity from "./infinity.png";
import svglogo from "./bland-logo-black.svg";
// import BlandClient from "./BlandClient.js";
import { BlandWebClient } from "bland-client-js-sdk";

const sdk = new BlandWebClient(
  '46f37229-7d12-44be-b343-6e68274cfbea',
  'bb26f357-660a-462e-8bac-49fc30a578fb'
);

// const socket = io("https://browser-stream-production.up.railway.app");
// const socket = io('http://localhost:3000')
function App() {
  const [recording, setRecording] = useState(false);
  const [transcripts, setTranscripts] = useState([]);
  const [streaming, setStreaming] = useState(false);
  const processorRef = useRef(null);
  const inputRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  // const audioContextRef = useRef(new AudioContext());
  const sourceRef = useRef(null);
  const [queue, setQueue] = useState([]); // Initialize the queue
  const [roomId, setRoomId] = useState(""); // Room ID
  const [joined, setJoined] = useState(false);
  const [responses, setResponses] = useState([]);
  const [currStreamId, setCurrStreamId] = useState(null);
  const [lastStreamId, setLastStreamId] = useState(null);
  const [isPlayingCircle, setIsPlayingCircle] = useState(false);
  const setIsPlayingCircleStable = useCallback(setIsPlayingCircle, []);
  const [isOpen, setIsOpen] = useState(false);
  const [isCalling, setIsCalling] = useState(false);

  const [audioIsPlaying, setAudioIsPlaying] = useState(false);
  const [hasFinishedLoadup, setHasFinishedLoadup] = useState(false);

  useEffect(() => {
    sdk.on("conversationStarted", () => {
      console.log('conversationStarted');
    });

    sdk.on("conversationEnded", ({ code, reason }) => {
      console.log("Closed with code:", code, ", reason:", reason);
    });

    sdk.on("error", (error) => {
      console.log("Error occured", error);
      setIsCalling(false);
    })

    sdk.on("update", (update) => {
      console.log('update', update);
    });
  }, []);

  // const unlockAudio = () => {
  //   if (audioContextRef.current.state === "suspended") {
  //     audioContextRef.current.resume().then(() => {
  //       console.log("AudioContext resumed successfully");
  //     });
  //   }
  // };

  // useEffect(() => {
  //   unlockAudioContext(audioContextRef.current);
  // }, []);
  // const initBland = async (isPlaying, setIsPlaying) => {
  //   const sdk = new BlandClient("", isPlaying, setIsPlaying);
  //   await sdk.initialize(
  //     "62b653fb-a677-4e41-acaf-d569c324e43f",
  //     "eb61f2de-c344-448f-9136-20fdf9ca93c1"
  //   );
  //   await sdk.recordAudio();
  // };


  // function unlockAudioContext(audioContext) {
  //   // if (audioContext?.state === "suspended") {
  //   //   const unlock = () => {
  //   //     audioContext?.resume().then(() => {
  //   //       document.body.removeEventListener("touchstart", unlock);
  //   //       document.body.removeEventListener("click", unlock);
  //   //     });
  //   //   };

  //   //   document.body.addEventListener("touchstart", unlock, false);
  //   //   document.body.addEventListener("click", unlock, false);
  //   // }
  // }

  // useEffect(() => {
  //   if (hasFinishedLoadup) {
  //     initBland(isPlayingCircle, setIsPlayingCircleStable);
  //   }
  // }, [hasFinishedLoadup]);

  const handleStartConversation = async () => {
    if (isCalling) {
      setIsCalling(false);
      sdk.stopConversation();
    } else {
      sdk.initConversation({
        callId: "test",
        sampleRate: 44100,
      })
        .catch(console.error);

      setIsCalling(true);
    };
  }


  const toggleMenu = () => {
    // Toggle the isOpen state
    setIsOpen(!isOpen);

    // Toggle the visibility of the menu items immediately
    const menuItems = document.querySelector(".menu-items");
    if (menuItems.style.visibility === "hidden") {
      menuItems.style.visibility = "visible";
    } else {
      menuItems.style.visibility = "hidden";
    }
  };

  return (
    <div className="w-screen h-screen  flex flex-col">
      <img className=" w-full absolute h-screen top-0" src={bgImage}></img>

      {/* LOGO */}
      <div className="absolute w-full flex items-center justify-center z-50">
        {/* <h1 className="mt-16 text-3xl font-bold text-white">
          Bland <span className="italic">Web</span>
        </h1> */}
      </div>

      <nav className="absolute top-0 left-0 right-0 flex flex-col   bg-opacity-75 z-50 ">
        <div className="md:hidden sm:block w-full bg-red-400 shadow-sm border-b border-red-400 py-2 px-2 z-50 text-white font-light text-xs">
          We're experiencing a high volume of users. If Bland isn't behaving correctly on mobile, you can do a phone call via <span className="underline text-indigo-500" onClick={() => { window.location.href = "https://bland.ai" }}>bland.ai</span>  (our home page) or use the portal <span className="underline text-indigo-500" onClick={() => { window.location.href = "https://app.bland.ai" }}>app.bland.ai</span> to build your own.
        </div>
        <div className="w-full flex flex-rows items-center justify-between gap-20  md:mt-4 sm:-mt-48 p-3">


          <div className="ml-10 flex-grow-0 flex-shrink-0">
            <a href="https://www.bland.ai">
              <img src={svglogo} alt="logo" />
            </a>
          </div>
          <div className="md:mt-4 w-full hidden lg:flex items-center justify-evenly py-2 w-full rounded-lg bg-gray-200 backdrop-filter bg-blur-xl bg-opacity-40 shadow-md shadow-gray-600">
            <a
              href="https://www.bland.ai"
              className="text-[#1a1a1a] text-opacity-90 hover:text-indigo-700 rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm "
            >
              HOME
            </a>
            <a
              href="https://forms.default.com/361589"
              className="text-[#1a1a1a] text-opacity-30 hover:text-indigo-700 rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm "
            >
              ENTERPRISE
            </a>
            <a
              href="https://app.bland.ai"
              className="text-[#1a1a1a] text-opacity-30 hover:text-indigo-700 rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm "
            >
              DOCUMENTATION
            </a>
            <a
              href="https://docs.bland.ai/welcome-to-bland"
              className="text-[#1a1a1a] text-opacity-30 hover:text-indigo-700 rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm "
            >
              DEV PORTAL
            </a>
            <a
              href="https://discord.gg/8xGGg2KfH7"
              className="text-[#1a1a1a] text-opacity-30 hover:text-indigo-700 rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm "
            >
              <div className="flex space-x-2">
                <FaDiscord className="inline-block" size={22} />
                <p>DISCORD</p>
              </div>
            </a>
            <a
              href="https://forms.default.com/18979"
              className="text-[#1a1a1a] text-opacity-30 hover:text-indigo-700 rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm"
            >
              <div className="flex space-x-2 items-center">
                <span className="text-lg">💫</span>
                <p>NEW AFFILIATE PROGRAM</p>
              </div>
            </a>
          </div>
          <div className="hidden lg:block flex-grow-0 flex-shrink-0">
            <a href="https://app.bland.ai">
              <div className="flex bg-[#6E65EF] hover:bg-indigo-700 mr-10 items-center px-2 py-2 rounded-lg mt-4">
                <button className="px-2 py-2 rounded-lg text-white opacity-80 tracking-wider font-gt-america-mono text-sm">
                  Start For Free
                </button>
                <div className="bg-indigo-400 rounded-full flex items-center justify-center h-8 w-8 ">
                  <MdOutlineArrowOutward size={32} color="white" />
                </div>
              </div>
            </a>
          </div>
          <button
            onClick={toggleMenu}
            className={`hamburger-menu space-y-2 ${isOpen ? "open" : ""
              } lg:hidden`}
          >
            <span className="block w-8 h-0.5 bg-black"></span>
            <span className="block w-8 h-0.5 bg-black"></span>
            <span className="block w-8 h-0.5 bg-black"></span>
          </button>
          <div
            className={`menu-items absolute top-full left-0 right-0 flex bg-red-200 flex-col items-start p-4 transition-opacity duration-200 ease-in-out lg:hidden ${isOpen ? "opacity-100" : "opacity-0"
              } `}
            style={{ visibility: isOpen ? "visible" : "hidden" }}
          >
            <a
              href="https://www.bland.ai"
              className="text-[#0c1e2666] hover:text-black hover:bg-white rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm"
            >
              HOME
            </a>
            <a
              href="https://forms.default.com/361589"
              className="text-[#0c1e2666] hover:text-black hover:bg-white rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm"
            >
              ENTERPRISE INQUIRY
            </a>
            <a
              href="https://app.bland.ai"
              className="text-[#0c1e2666] hover:text-black hover:bg-white rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm"
            >
              DOCUMENTATION
            </a>
            <a
              href="https://docs.bland.ai/welcome-to-bland"
              className="text-[#0c1e2666] hover:text-black hover:bg-white rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm"
            >
              DEV PORTAL
            </a>
            <a
              href="https://discord.gg/8xGGg2KfH7"
              className="text-[#0c1e2666] hover:text-black hover:bg-white rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm"
            >
              <div className="flex space-x-2">
                <FaDiscord className="inline-block" size={22} />
                <p>DISCORD</p>
              </div>
            </a>
            <a
              href="https://forms.default.com/18979"
              className="text-[#0c1e2666] hover:text-black hover:bg-white rounded-full px-4 py-2 transition ease-in-out duration-300 font-gt-america-mono text-sm"
            >
              <div className="flex space-x-2">
                <span className="text-xl">💫</span>
                <p>NEW AFFILIATE PROGRAM</p>
              </div>
            </a>
            <a href="https://app.bland.ai/signup">
              <div className="flex bg-[#6E65EF] hover:bg-indigo-700 mr-10 items-center px-2 py-2 rounded-lg mt-4">
                <button className="px-2 py-2 rounded-lg text-white  tracking-wider font-gt-america-mono text-xs">
                  Start For Free
                </button>
                <div className="bg-indigo-400 rounded-full flex items-center justify-center h-8 w-8 ">
                  <MdOutlineArrowOutward size={32} color="white" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </nav>

      {/* Header */}
      {/* <header className="bg-white shadow-md p-4 flex justify-between items-center">
        <div className="text-black font-bold text-2xl">
          Intelliga V0 (WEB)
        </div>

      </header> */}

      {/* Chat advisor */}
      <div className="mx-auto my-auto z-40 w-1/2 flex flex-col">
        <h1 className="text-2xl font-extrabold text-center mb-4 tracking-tight text-gray-900 rounded-md z-40">
          {/* 
          <video width="750" height="500" autoPlay="true" >
            <source src={animation} type="video/mp4" />
          </video> */}
          {/*<GifPlayerModified
            setHasFinishedLoadup={setHasFinishedLoadup}
            recording={recording}
            autoplay={recording}
            className={`my-auto mx-auto text-8xl text-purple-500  w-48 opacity-20 transition-all  duration-1000 ${recording ? "-rotate-45  w-24" : "rotate-0 w-18"
              } `}
          ></GifPlayerModified>*/}
        </h1>
        {/* 
        <p className="text-gray-400 text-center font-medium uppercase tracking-widest">
          Room ID: <span className="text-gray-300">{roomId}</span>
        </p> */}

        {/* <p className="text-gray-400 text-center font-medium uppercase tracking-widest my-4">

          <p className="lowercase text-xs">Fully conversational, and with complete product knowledge.</p>
        </p> */}


        {!hasFinishedLoadup && (
          <h1 className="mt-0 text-4xl font-bold text-white text-center mb-14 font-gt-america-mono">
            Bland <span className="italic">Web</span>
          </h1>
        )}

        {isCalling && (
          <div className="items-center justify-center mx-auto" style={{marginTop:"-10%"}}>
            <img src={require("./ListeningTwo.gif")} alt="loading..." />
          </div>
        )}

        <div
          style={{ maxWidth: "400px" }}
          className={`font-gt-america-mono text-center py-3 w-full mx-auto bg-white opacity-80 text-purple-500 hover:opacity-100 rounded-lg cursor-pointer text-base items-center justify-center flex font-bold tracking-wide transition-all duration-[500] transition-all ${recording && "  hidden "
            } display:block shadow-md font-mono`}
          onClick={() => handleStartConversation()}
        >
          {isCalling ? (
            "Stop"
          ) : (
            <span className="font-gt-america-mono">Begin</span>
          )}
        </div>

        {hasFinishedLoadup && (
          <div className="absolute w-screen h-screen  top-0 left-0 ">
            <div className="mx-auto my-auto">
              <div className="absolute w-screen h-screen top-0 left-0 flex">
                <div className="mx-auto my-auto w-full flex relative">
                  <div className="absolute w-full h-full flex ">
                    {/* <img className="mx-auto my-auto w-32 opacity-24  " src={infinity}></img> */}
                  </div>
                  {/* Key the component on the isPlayingCircle state */}
                  <MorphingCircle
                    // key={isPlayingCircle}
                    isPlaying={isPlayingCircle}
                    setIsPlaying={setIsPlayingCircle}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-4 rounded-md ">
          {/* <h2 className="text-lg font-bold mb-2 uppercase tracking-tight shadow-md">Replies:</h2> */}
          <ul className="divide-y divide-gray-700 space-y-2 rounded-md flex flex-col w-full px-2 relative">
            {/* {responses.map((resp, index) => (
              <li key={index} className={`${resp?.role === 'user' ? 'bg-blue-400 rounded-sm p-4 w-2/3 ml-auto rounded-tr-xl rounded-bl-xl rounded-tl-xl text-sm border-none' : 'bg-gray-300 border-none text-gray-800 rounded-sm p-4 w-2/3 rounded-tr-2xl rounded-br-2xl rounded-tl-2xl mr-auto rounded-br-none text-sm'}`}>
                {resp?.response}
              </li>
            ))} */}
          </ul>
        </div>

        {/* {isPlaying && (
          <div className="mt-4 flex items-center justify-center space-x-2">
            <AiOutlineLoading className="h-6 w-6 text-blue-400 animate-spin" />
            <span className="text-sm tracking-wide text-blue-500">
              AI is speaking...
            </span>
          </div>
        )} */}

        <div className="absolute inset-x-0 bottom-0 mx-auto w-max mb-16">
          <a
            className="text-white underline font-gt-america-mono"
            href="https://app.bland.ai"
          >
            Try the Bland SDK
          </a>

        </div>
      </div>
    </div>
  );
}
export default App;
