import { useEffect, useRef, useState } from 'react';
import paper from 'paper';
import infinity from "./infinity.png"

const MorphingCircle = ({ isPlaying, setIsPlaying }) => {
    const canvasRef = useRef(null);
    const originalPointsRef = useRef([]); // Use useRef to persist original points
    const infinityImgRef = useRef(null);
    const [morphingPaperToDraw, setMorphingPaper] = useState(null)
    const circleRef = useRef(null); const radius = 85;
    const [speed, setSpeed] = useState(10)
    useEffect(() => {
        setSpeed(speed * Math.random())

        if (!isPlaying) {
            const resetCircle = () => {
                if (circleRef.current && originalPointsRef.current.length) {
                    circleRef.current.segments.forEach((segment, index) => {
                        segment.point.x = originalPointsRef.current[index].x;
                        segment.point.y = originalPointsRef.current[index].y;
                    });
                }
            };

            if (circleRef.current && circleRef.current.rndPos) {
                const diameter = radius * 2;



                // circleRef.current.rndPos = [
                //     [rndPoint(0, 0.05 * diameter, 'x'), rndPoint(0.5 * diameter, 0.5 * diameter, 'y')],
                //     [rndPoint(0.1 * diameter, 0.6 * diameter, 'x'), rndPoint(0, 0.1 * diameter, 'y')],
                //     [rndPoint(0.95 * diameter, 1.05 * diameter, 'x'), rndPoint(0.45 * diameter, 0.6 * diameter, 'y')],
                //     [rndPoint(0.5 * diameter, 0.6 * diameter, 'x'), rndPoint(0.9 * diameter, 1.05 * diameter, 'y')],
                // ];
                // const resetCircle = () => {
                //     if (circleRef.current && originalPointsRef.current.length) {
                //         circleRef.current.segments.forEach((segment, index) => {
                //             segment.point.x = originalPointsRef.current[index].x;
                //             segment.point.y = originalPointsRef.current[index].y;
                //         });
                //     }
                // };
                // // resetCircle();
                setTimeout(() => {
                    circleRef.current.shouldPlay = false;

                }, 200)

            } else {

            }
        } else {
            if (circleRef.current) {
                const diameter = radius * 2;

                circleRef.current.rndPos = [
                    [rndPoint(0, 0.05 * diameter, 'x'), rndPoint(0.45 * diameter, 0.6 * diameter, 'y')],
                    [rndPoint(0.45 * diameter, 0.6 * diameter, 'x'), rndPoint(0, 0.15 * diameter, 'y')],
                    [rndPoint(0.9 * diameter, 1.05 * diameter, 'x'), rndPoint(0.45 * diameter, 0.6 * diameter, 'y')],
                    [rndPoint(0.45 * diameter, 0.6 * diameter, 'x'), rndPoint(0.9 * diameter, 1.05 * diameter, 'y')],
                ];
                circleRef.current.shouldPlay = true;

            }

        }

    }, [isPlaying])

    const rndPoint = (min, max, pos) => {
        if (!isPlaying) {
            const centerX = window.innerWidth / 2;
            const centerY = window.innerHeight / 2;
            if (pos === 'x') {
                min += centerX - radius;
                max += centerX - radius;
            } else {
                min += centerY - radius;
                max += centerY - radius;
            }

            return Math.random() * (max - min) + min;
        } else {
            const centerX = window.innerWidth / 2;
            const centerY = window.innerHeight / 2;
            if (pos === 'x') {
                min += centerX - radius;
                max += centerX - radius;
            } else {
                min += centerY - radius;
                max += centerY - radius;
            }

            return Math.random() * (max - min) + min;
        }


    };


    const gensmallPoints = () => {
        const diameter = radius * 2;
        const tightness = 0.04 // Adjust this value to control the tightness of the morphing

        circleRef.current.rndPos = [
            [rndPoint(0, tightness * diameter, 'x'), rndPoint(0.45 * diameter, (0.45 + tightness) * diameter, 'y')],
            [rndPoint(0.45 * diameter, (0.45 + tightness) * diameter, 'x'), rndPoint(0, tightness * diameter, 'y')],
            [rndPoint((0.9 - tightness) * diameter, 0.9 * diameter, 'x'), rndPoint(0.45 * diameter, (0.45 + tightness) * diameter, 'y')],
            [rndPoint(0.45 * diameter, (0.45 + tightness) * diameter, 'x'), rndPoint((0.9 - tightness) * diameter, 0.9 * diameter, 'y')],
        ];



    };
    const generateRndPoints = () => {
        const diameter = radius * 2;

        circleRef.current.rndPos = [
            [rndPoint(0, 0.05 * diameter, 'x'), rndPoint(0.45 * diameter, 0.6 * diameter, 'y')],
            [rndPoint(0.45 * diameter, 0.6 * diameter, 'x'), rndPoint(0, 0.15 * diameter, 'y')],
            [rndPoint(0.9 * diameter, 1.05 * diameter, 'x'), rndPoint(0.45 * diameter, 0.6 * diameter, 'y')],
            [rndPoint(0.45 * diameter, 0.6 * diameter, 'x'), rndPoint(0.9 * diameter, 1.05 * diameter, 'y')],
        ];



    };

    const draw = (morphingPaper) => {


        morphingPaper.view.onFrame = (event) => {
            if (circleRef.current) {
                for (let j = 0; j < 4; j++) {

                    let dX1 = (circleRef.current.rndPos[j][0] - circleRef.current.segments[j].point.x) / circleRef.current.speed * Math.random();
                    let dY1 = (circleRef.current.rndPos[j][1] - circleRef.current.segments[j].point.y) / circleRef.current.speed * Math.random() + 0.3;

                    circleRef.current.segments[j].point.x += dX1;
                    circleRef.current.segments[j].point.y += dY1;
                    if (infinityImgRef.current) {
                        const circleCenter = circleRef.current.position;

                        const angle = event.count / 50; // Adjust the divisor to control the speed
                        const radius = 0.5; // Adjust the radius for larger or smaller movement
                        const imageX = circleCenter.x - (infinityImgRef.current.offsetWidth / 2);
                        const imageY = circleCenter.y - (infinityImgRef.current.offsetHeight / 2);

                        infinityImgRef.current.style.transform = `translate(${imageX}px, ${imageY}px)`;
                    }

                    if (Math.floor(circleRef.current.segments[j].point.x) === Math.floor(circleRef.current.rndPos[j][0])) {
                        if (circleRef.current.shouldPlay) {
                            circleRef.current.speed = 10
                            generateRndPoints();

                        } else {
                            circleRef.current.speed = 40
                            gensmallPoints()
                        }

                    }
                }
            }


        };
        paper.view.rotate(0.01, paper.view.center);

    };

    const setMorphingBg = (morphingPaper) => {
        const centerX = window.innerWidth / 2;
        const centerY = window.innerHeight / 2;

        const circle = new morphingPaper.Path.Circle(new paper.Point(centerX, centerY), radius);
        circleRef.current = circle;

        // Define the gradient for the fill
        const gradient = new morphingPaper.Gradient([
            { color: '#716DF7', offset: 0.2 },  // Darker shade //7775C9
            // Closer to FACADC, but still distinct
            // { color: '#7775C9', offset: 0.8 },
            { color: '#9793FE', offset: 1 },  // Lightest shade
        ], true);

        // Define gradient color
        const from = circle.position;
        const to = circle.bounds.rightCenter;
        const gradientColor = new morphingPaper.Color(gradient, from, to);

        // Store original points
        const originalPoints = circle.segments.map(segment => segment.point.clone());
        circleRef.current.shouldPlay = true;

        // Set fill color
        circle.fillColor = gradientColor;

        // Initialize random positions array
        circle.rndPos = [];

        // Set speed
        circleRef.current.speed = 10;

        // Store original positions for later use
        originalPointsRef.current = originalPoints;

        // Add white border
        circle.strokeColor = 'white'; // Set the stroke color to white
        circle.strokeWidth = 0.2; // Set the stroke width (adjust this value as needed)

        // Add glow effect using shadow properties
        circle.shadowColor = new morphingPaper.Color(0, 0, 0, 0.3); // Set the shadow color to semi-transparent black
        circle.shadowBlur = 15; // Set the amount of blur
        circle.shadowOffset = new morphingPaper.Point(2, 4);

        generateRndPoints();
        draw(morphingPaper);

        // setMorphingPaper
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        paper.setup(canvas);
        setMorphingPaper(paper)


        setMorphingBg(paper);


        // Clean up on component unmount
    }, []);
    useEffect(() => {

    }, [morphingPaperToDraw])
    useEffect(() => {
        if (morphingPaperToDraw) {

        }
        // if (!isPlaying && morphingPaperToDraw) {
        //     setMorphingPaper(null)

        // }
    }, [isPlaying])
    const togglePlayPause = () => {
        setIsPlaying(!isPlaying);
    };
    return (
        <>

            <canvas ref={canvasRef} resize="true" style={{ width: '100%', height: '100vh' }}>

            </canvas>
            <img ref={infinityImgRef} className="mx-auto my-auto w-24 opacity-80 absolute z-[5000]" src={infinity} alt="Infinity Symbol"></img>




        </>)



};

export default MorphingCircle;



